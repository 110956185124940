<script>
import Vue from "vue";
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import Preloader from "@/components/preloader";
import Multiselect from "vue-multiselect";
import Switches from "vue-switches";
import appConfig from "@/app.config";
import { required, numeric, minValue } from "vuelidate/lib/validators";
import { typeOfTransferData } from "@/data/data-type-of-transfer";
import { typeOfApplications } from "@/data/data-type-of-application-status";
import { dataTypeOfBankFiles } from "@/data/data-type-of-bank-files";
import { roleService } from "@/app/service/roleService";
import Repository from "@/app/repository/repository-factory";

const PaymentBankRepository = Repository.get("PaymentBankRepository");
const PaymentBankFileTemplatesRepository = Repository.get(
  "PaymentBankFileTemplatesRepository"
);

/**
 * Edycja szablonu pliku bankowego
 */

export default {
  page: {
    title: "Edycja szablonu pliku bankowego",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Preloader,
    Switches,
    Multiselect,
  },
  data() {
    return {
      header: {
        title: "Edytuj szablon pliku bankowego",
        items: [
          {
            text: "Strona główna",
            to: { name: "home" },
          },
          {
            text: "Lista szablonów",
            to: { name: "Lista szablonów plików bankowych" },
          },
          {
            text: "Edycja szablonu pliku bankowego",
          },
        ],
      },
      preloader: true,
      disabledBtn: false,
      form: {
        bankId: null,
        codePage: null,
        feesStandard: null,
        fileExtension: null,
        fileType: null,
        isDefault: false,
        isActive: false,
        maxCharacters: null,
        maxRecordsInFile: null,
        maxFileNameLength: null,
        splitEveryLine: false,
        splitSymbolsInTaxTransferTitle: false,
        splitTitle: false,
        taxTransferTitleInTwoLines: false,
        taxSymbolModifier: null,
        textModifier: null,
        transferCode: null,
        transferType: null,
        typeOfApplication: null,
        typeOfTransfer: null,
        sorbnetAscii: null,
      },
      bankSelectOptions: [],
      typeOfApplicationOptions: typeOfApplications,
      typeOfTransferOptions: typeOfTransferData,
      fileTypeOptions: dataTypeOfBankFiles,
    };
  },
  validations: {
    form: {
      bankId: {
        required,
      },
      codePage: {
        required,
      },
      feesStandard: {
        numeric,
      },
      fileExtension: {
        required,
      },
      fileType: {
        required,
      },
      isDefault: {
        required,
      },
      isActive: {
        required,
      },
      maxCharacters: {
        required,
      },
      maxRecordsInFile: {
        required,
      },
      maxFileNameLength: {
        numeric,
        minValue: minValue(12),
      },
      splitEveryLine: {
        required,
      },
      splitSymbolsInTaxTransferTitle: {
        required,
      },
      splitTitle: {
        required,
      },
      taxTransferTitleInTwoLines: {},
      taxSymbolModifier: {},
      textModifier: {},
      transferCode: {
        required,
        numeric,
      },
      transferType: {
        required,
        numeric,
      },
      typeOfApplication: {
        required,
      },
      typeOfTransfer: {
        required,
      },
      sorbnetAscii: {
        required,
      },
    },
  },
  created() {
    this.preloader = true;
    this.getBankFileTemplates();
    this.getBanks();
  },
  methods: {
    getBankFileTemplates() {
      if (this.$route.params.id) {
        PaymentBankFileTemplatesRepository.get(this.$route.params.id)
          .then((response) => {
            this.form = response.data;
            this.getBankDataById();

            let fileTypeSelect = this.fileTypeOptions.filter((obj) => {
              return obj.value === response.data.fileType;
            });
            this.form.fileType = fileTypeSelect[0];

            this.preloader = false;
          })
          .catch((error) => {
            console.log(error);
            this.preloader = false;
          });
      }
    },
    getBanks() {
      PaymentBankRepository.getAll()
        .then((response) => {
          this.bankSelectOptions = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getBankDataById() {
      try {
        const { data } = await PaymentBankRepository.get(this.form.bankId);
        this.form.bankId = data;
      } catch (error) {
        console.log(error);
      }
    },
    updateBankFileTemplate() {
      this.formSubmit();

      if (this.$v.$error === true) {
        return false;
      }

      let payload = Object.assign({}, this.form);

      payload.bankId = this.form.bankId.bankId;
      payload.typeOfApplication = this.form.typeOfApplication.id;
      payload.typeOfTransfer = this.form.typeOfTransfer.id;
      payload.fileType = this.form.fileType.value;
      payload.version = this.form.version;
      payload.maxFileNameLength = this.form.maxFileNameLength == '' ? null : this.form.maxFileNameLength;

      Vue.swal({
        title: "Czy jesteś pewien?",
        text: "Operacja jest nieodwracalna!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Tak, zmień dane!",
        cancelButtonText: "Anuluj",
        confirmButtonColor: "#61aa3d",
        cancelButtonColor: "#e2001a",
      }).then((result) => {
        if (result.value) {
          this.disabledBtn = true;
          this.preloader = true;
          return PaymentBankFileTemplatesRepository.update(
            payload,
            this.$route.params.id
          )
            .then(() => {
              this.disabledBtn = false;
              this.preloader = false;
              Vue.swal({
                icon: "success",
                toast: false,
                position: "top",
                title: "SUKCES!",
                text: "Szablon pliku bankowego został zaktualizowany!",
                showConfirmButton: false,
                timer: 3500,
                onClose: () => {
                  this.$router.push({
                    name: "Lista szablonów plików bankowych",
                  });
                },
              });
            })
            .catch((error) => {
              this.disabledBtn = false;
              this.preloader = false;
              Vue.swal({
                icon: "error",
                position: "top",
                title: "Wystąpił błąd. Spróbuj jeszcze raz!",
                text: error.response.data.message,
                showConfirmButton: true,
              });
            });
        }
      });
    },
    formSubmit(e) {
      this.$v.$touch();
    },
    countryLabel({ name_pl, name_en, code }) {
      return `${name_pl} ${name_en} ${code} `;
    },
    bankLabel({ name, shortName, bankId }) {
      return `${name} ${shortName} ${bankId}`;
    },
  },
  computed: {
    isAdmin() {
      return roleService.isAdmin();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="header.title" :items="header.items" />
    <Preloader v-if="preloader" />

    <b-form @submit.prevent="formSubmit" v-if="isAdmin">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow-sm">
            <b-card-header>
              <div class="row align-items-center">
                <div class="col">Formularz edycji szablonu pliku bankowego</div>
                <div class="col text-right">
                  <b-link class="btn btn-sm btn-warning" :to="{ name: 'Lista szablonów plików bankowych' }">Anuluj</b-link>
                </div>
              </div>
            </b-card-header>
            <div class="card-body">
              <b-alert variant="warning" class="mb-3" show fade>
                <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> Wartości w formularzu powinny być wprowadzone na podstawie dokumentacji technicznej każdego banku, osobno dla każdego typu wniosku.
              </b-alert>
              <b-alert variant="danger" class="mb-3" show fade>
                <i class="mdi mdi-shield-alert-outline mr-2"></i><strong>Uwaga!</strong> <strong>Zmiany wartości w formularzu będą miały wpływ na wygenerowane pliki bankowe!</strong><br />
                Błędnie wprowadzone parametry zatrzymają proces generowania plików bankowych lub uniemożliwią ich wczytanie do systemów bankowych!
              </b-alert>
              <div class="row justify-content-center">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="bankId">
                      Wybierz bank
                      <em class="text-danger">*</em>
                    </label>
                    <multiselect id="bankId" v-model.trim="form.bankId" :options="bankSelectOptions" :custom-label="bankLabel" :class="{ 'is-invalid': $v.form.bankId.$error, 'is-valid': !$v.form.bankId.$invalid && !$v.form.bankId.$error }" track-by="bankId" name="bankId" placeholder="Wybierz bank z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                          <br />
                          <span class="option__small small text-dark">{{ props.option.shortName }}</span>
                        </div>
                      </template>
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group" :class="{ 'is-invalid' : $v.form.typeOfApplication.$error }">
                    <label for="typeOfApplication">
                      Typ wniosku
                      <em class="text-danger">*</em>
                    </label>
                    <multiselect id="typeOfApplication" v-model.number="form.typeOfApplication" :options="typeOfApplicationOptions" track-by="index" @input="$v.form.typeOfApplication.$touch()" :class="{ 'is-invalid': $v.form.typeOfApplication.$error, 'is-valid': !$v.form.typeOfApplication.$invalid && !$v.form.typeOfApplication.$error }" name="transferFeesAndChargesSelectField" placeholder="Wybierz z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                        </div>
                      </template>
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group" :class="{ 'is-invalid' : $v.form.typeOfTransfer.$error }">
                    <label for="typeOfTransfer">
                      Rodzaj wniosku
                      <em class="text-danger">*</em>
                    </label>
                    <multiselect id="typeOfTransfer" v-model.number="form.typeOfTransfer" :options="typeOfTransferOptions" track-by="index" @input="$v.form.typeOfTransfer.$touch()" :class="{ 'is-invalid': $v.form.typeOfTransfer.$error, 'is-valid': !$v.form.typeOfTransfer.$invalid && !$v.form.typeOfTransfer.$error }" name="transferFeesAndChargesSelectField" placeholder="Wybierz z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.value }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.value }}</span>
                        </div>
                      </template>
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group" :class="{ 'is-invalid' : $v.form.fileType.$error }">
                    <label for="fileType">
                      Typ szablonu
                      <em class="text-danger">*</em>
                    </label>
                    <multiselect id="fileType" v-model.trim="form.fileType" :options="fileTypeOptions" track-by="index" @input="$v.form.fileType.$touch()" :class="{ 'is-invalid': $v.form.fileType.$error, 'is-valid': !$v.form.fileType.$invalid && !$v.form.fileType.$error }" name="transferFeesAndChargesSelectField" placeholder="Wybierz z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                        </div>
                      </template>
                     <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <hr />
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-md">
                  <div class="form-group">
                    <label for="codePage">
                      codePage
                      <em class="text-danger">*</em>
                    </label>
                    <input id="codePage" v-model.trim="form.codePage" @input="$v.form.codePage.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.codePage.$error, 'is-valid': !$v.form.codePage.$invalid && !$v.form.codePage.$error }" type="text" name="name" placeholder="codePage" value required />
                    <small id="cardholderNumberHelp" class="form-text text-muted"><i class="mdi mdi-information-outline mr-1"></i>Strona kodowa (np.: CP852)</small>
                    <div v-if="$v.form.codePage.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.codePage.$error && !$v.form.codePage.required">Pole jest wymagane!</span>
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="fileExtension">
                      fileExtension
                      <em class="text-danger">*</em>
                    </label>
                    <input id="fileExtension" v-model.trim="form.fileExtension" @input="$v.form.fileExtension.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.fileExtension.$error, 'is-valid': !$v.form.fileExtension.$invalid && !$v.form.fileExtension.$error }" type="text" name="name" placeholder="fileExtension" value required />
                    <small id="cardholderNumberHelp" class="form-text text-muted"><i class="mdi mdi-information-outline mr-1"></i>Rozszerzenie pliku (np.: pli)</small>
                    <div v-if="$v.form.fileExtension.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.fileExtension.$error && !$v.form.fileExtension.required">Pole jest wymagane!</span>
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="maxCharacters">
                      maxCharacters
                      <em class="text-danger">*</em>
                    </label>
                    <input id="maxCharacters" v-model.number="form.maxCharacters" @input="$v.form.maxCharacters.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.maxCharacters.$error, 'is-valid': !$v.form.maxCharacters.$invalid && !$v.form.maxCharacters.$error }" type="text" name="name" placeholder="maxCharacters" value required />
                    <small id="cardholderNumberHelp" class="form-text text-muted"><i class="mdi mdi-information-outline mr-1"></i>Maksymalna liczba znaków dla tytułu przelewu skarbowego/split payment (domyślnie: 20)</small>
                    <div v-if="$v.form.maxCharacters.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.maxCharacters.$error && !$v.form.maxCharacters.required">Pole jest wymagane!</span>
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="maxFileNameLength">
                      maxFileNameLength
                    </label>
                    <input id="maxFileNameLength" v-model.number="form.maxFileNameLength" @input="$v.form.maxFileNameLength.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.maxFileNameLength.$error, 'is-valid': !$v.form.maxFileNameLength.$invalid && !$v.form.maxFileNameLength.$error }" type="text" name="name" placeholder="maxFileNameLength" value />
                    <small id="cardholderNumberHelp" class="form-text text-muted"><i class="mdi mdi-information-outline mr-1"></i>Maksymalna liczba znaków dla nazwy pliku (nieuzupełnione lub liczba większa od 12)</small>
                    <div v-if="$v.form.maxFileNameLength.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.maxFileNameLength.$error && !$v.form.maxFileNameLength.minValue">Minimalna wartość to 12!</span>
                      <span class="d-block" v-if="$v.form.maxFileNameLength.$error && !$v.form.maxFileNameLength.numeric">Pole przyjmuje wyłącznie wartości liczbowe!</span>
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="maxRecordsInFile">
                      maxRecordsInFile
                      <em class="text-danger">*</em>
                    </label>
                    <input id="maxRecordsInFile" v-model.number="form.maxRecordsInFile" @input="$v.form.maxRecordsInFile.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.maxRecordsInFile.$error, 'is-valid': !$v.form.maxRecordsInFile.$invalid && !$v.form.maxRecordsInFile.$error }" type="text" name="name" placeholder="maxRecordsInFile" value required />
                    <small id="cardholderNumberHelp" class="form-text text-muted"><i class="mdi mdi-information-outline mr-1"></i>Maksymalna liczba przelewów w jednym pliku (np.: 500)</small>
                    <div v-if="$v.form.maxRecordsInFile.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.maxRecordsInFile.$error && !$v.form.maxRecordsInFile.required">Pole jest wymagane!</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-md">
                  <div class="form-group">
                    <label for="transferCode">
                      transferCode
                      <em class="text-danger">*</em>
                    </label>
                    <input id="transferCode" v-model.number="form.transferCode" @input="$v.form.transferCode.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.transferCode.$error, 'is-valid': !$v.form.transferCode.$invalid && !$v.form.transferCode.$error }" type="text" name="name" placeholder="transferCode" value required />
                    <small id="cardholderNumberHelp" class="form-text text-muted"><i class="mdi mdi-information-outline mr-1"></i>Kod przelewu (np.: przelew zwykły = 110)</small>
                    <div v-if="$v.form.transferCode.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.transferCode.$error && !$v.form.transferCode.required">Pole jest wymagane!</span>
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="transferType">
                      transferType
                      <em class="text-danger">*</em>
                    </label>
                    <input id="transferType" v-model.number="form.transferType" @input="$v.form.transferType.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.transferType.$error, 'is-valid': !$v.form.transferType.$invalid && !$v.form.transferType.$error }" type="text" name="name" placeholder="transferType" value required />
                    <small id="cardholderNumberHelp" class="form-text text-muted"><i class="mdi mdi-information-outline mr-1"></i>Kod typu przelewu (np.: przelew zwykły = 51, przelew skarbowy = 71)</small>
                    <div v-if="$v.form.transferType.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.transferType.$error && !$v.form.transferType.required">Pole jest wymagane!</span>
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="textModifier">
                      textModifier
                    </label>
                    <input id="textModifier" v-model.trim="form.textModifier" @input="$v.form.textModifier.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.textModifier.$error, 'is-valid': !$v.form.textModifier.$invalid && !$v.form.textModifier.$error }" type="text" name="name" placeholder="textModifier" value />
                    <small id="cardholderNumberHelp" class="form-text text-muted"><i class="mdi mdi-information-outline mr-1"></i>Modyfikator tekstu (np.: tylko duże litery alfabetu = UPPERCASE)</small>
                    <div v-if="$v.form.textModifier.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.textModifier.$error && !$v.form.textModifier.required">Pole jest wymagane!</span>
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="taxSymbolModifier">
                      taxSymbolModifier
                    </label>
                    <input id="taxSymbolModifier" v-model.trim="form.taxSymbolModifier" @input="$v.form.taxSymbolModifier.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.taxSymbolModifier.$error, 'is-valid': !$v.form.taxSymbolModifier.$invalid && !$v.form.taxSymbolModifier.$error }" type="text" name="name" placeholder="taxSymbolModifier" value />
                    <small id="cardholderNumberHelp" class="form-text text-muted"><i class="mdi mdi-information-outline mr-1"></i>Modyfikator symbolu podatkowego (pole niewypełnione lub mapped)</small>
                    <div v-if="$v.form.taxSymbolModifier.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.taxSymbolModifier.$error && !$v.form.taxSymbolModifier.required">Pole jest wymagane!</span>
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div class="form-group">
                    <label for="feesStandard">
                      feesStandard
                    </label>
                    <input id="feesStandard" v-model.number="form.feesStandard" @input="$v.form.feesStandard.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.feesStandard.$error, 'is-valid': !$v.form.feesStandard.$invalid && !$v.form.feesStandard.$error }" type="text" name="name" placeholder="feesStandard" value />
                    <small id="cardholderNumberHelp" class="form-text text-muted"><i class="mdi mdi-information-outline mr-1"></i>Standard opłat zagranicznych (nieuzupełnione, lub liczba 1-3, domyślnie 1)</small>
                    <div v-if="$v.form.feesStandard.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.feesStandard.$error && !$v.form.feesStandard.required">Pole jest wymagane!</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <hr />
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-md-3">
                  <div class="d-flex justify-content-start">
                    <switches class="mt-2" v-model="form.splitEveryLine" type-bold="false" :color="form.splitEveryLine == 1 ? 'success' : 'primary'"></switches>
                    <label class="mt-1 ml-3"><span class="mr-2">splitEveryLine:</span>
                      <span v-if="form.splitEveryLine" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
                      <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
                    </label>
                  </div>
                  <small id="cardholderNumberHelp" class="form-text text-muted mt-n2"><i class="mdi mdi-information-outline mr-1"></i>Każde podpole tytułu w nowej linii (0 lub 1, domyślnie 0)</small>
                </div>
                <div class="col-md-3">
                  <div class="d-flex justify-content-start">
                    <switches class="mt-2" v-model="form.splitSymbolsInTaxTransferTitle" type-bold="false" :color="form.splitSymbolsInTaxTransferTitle == 1 ? 'success' : 'primary'"></switches>
                    <label class="mt-1 ml-3"><span class="mr-2">splitSymbolsInTaxTransferTitle:</span>
                      <span v-if="form.splitSymbolsInTaxTransferTitle" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
                      <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
                    </label>
                  </div>
                  <small id="cardholderNumberHelp" class="form-text text-muted mt-n2"><i class="mdi mdi-information-outline mr-1"></i>Dzielenie symboli podpól w przelewie podatkowym (0 lub 1, domyślnie 0)</small>
                </div>
                <div class="col-md-3">
                  <div class="d-flex justify-content-start">
                    <switches class="mt-2" v-model="form.splitTitle" type-bold="false" :color="form.splitTitle == 1 ? 'success' : 'primary'"></switches>
                    <label class="mt-1 ml-3"><span class="mr-2">splitTitle:</span>
                      <span v-if="form.splitTitle" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
                      <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
                    </label>
                  </div>
                  <small id="cardholderNumberHelp" class="form-text text-muted mt-n2"><i class="mdi mdi-information-outline mr-1"></i>Dzielenie tytułu na linie (0 lub 1 domyślnie 1)</small>
                </div>
                <div class="col-md-3">
                  <div class="d-flex justify-content-start">
                    <switches class="mt-2" v-model="form.sorbnetAscii" type-bold="false" :color="form.sorbnetAscii == 1 ? 'success' : 'primary'"></switches>
                    <label class="mt-1 ml-3"><span class="mr-2">sorbnetAscii:</span>
                      <span v-if="form.sorbnetAscii" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
                      <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
                    </label>
                  </div>
                  <small id="cardholderNumberHelp" class="form-text text-muted mt-n2"><i class="mdi mdi-information-outline mr-1"></i>Usuwa polskie znaki dla operacji SORBNET</small>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <hr />
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <div class="d-flex justify-content-start">
                    <switches class="mt-2" v-model="form.isDefault" type-bold="false" :color="form.isDefault == 1 ? 'success' : 'primary'"></switches>
                    <label class="mt-1 ml-3"><span class="mr-2">Domyślny:</span>
                      <span v-if="form.isDefault" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
                      <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
                    </label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="d-flex justify-content-start">
                    <switches class="mt-2" v-model="form.taxTransferTitleInTwoLines" type-bold="false" :color="form.taxTransferTitleInTwoLines == 1 ? 'success' : 'primary'"></switches>
                    <label class="mt-1 ml-3"><span class="mr-2">taxTransferTitleInTwoLines:</span>
                      <span v-if="form.taxTransferTitleInTwoLines" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
                      <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
                    </label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="d-flex justify-content-start">
                    <switches class="mt-2" v-model="form.isActive" type-bold="false" :color="form.isActive == 1 ? 'success' : 'primary'"></switches>
                    <label class="mt-1 ml-3"><span class="mr-2">Aktywny:</span>
                      <span v-if="form.isActive" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
                      <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-md-12 mt-3">
                  <button class="btn btn-dark btn-block" v-on:click="updateBankFileTemplate" :disabled="$v.form.$invalid || disabledBtn" type="submit">Aktualizuj szablon pliku bankowego</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </Layout>
</template>
